//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

.breadcrumb-con{
	@include font-size(18);
	line-height: 1.2em;
	margin-bottom: 50px;
	color: $colour;
	font-weight: 400;
	display: flex;
	justify-content: flex-start;

	@media (max-width: $b3){
		margin-bottom: 30px;
	}

	span{
		color: white;
		@include font-size(18);
		line-height: 1.2em;
		display: inline-block;

		@media (max-width: $b3){
			@include font-size(12);
		}
	}

	a{
		@include font-size(18);
		letter-spacing: .015em;
		line-height: 1.2em;
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		color: $red;
		font-weight: 400;
		margin-right: .2em;
		position: relative;
		display: inline-block;

		@media (max-width: $b3){
			@include font-size(12);
		}

		&:after{
			content: '/';
			display: inline-block;
			margin-left: .4em;
			color: $colour;
		}


		&:hover,
		&:focus-visible{
			color: $red;
			text-decoration: none;
		}
	}

	> a{
		margin-left: 0;
	}

	.breadcrumb_last{
		@include font-size(18);
		letter-spacing: .015em;
		line-height: 1.2em;
		color: $colour;
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		font-weight: 400;
		position: relative;

		@media (max-width: $b3){
			@include font-size(12);
		}
	}
}

//
//!! BREADCRUM END
//

//.pagination-simple{
//	padding: 7px 0 2px;
//
//	a{
//		color: $red;
//		font-weight: 700;
//		padding-top: 1px;
//		@include inline;
//		@include font-size(20);
//		@include line-height(20,33);
//		letter-spacing: .03em;
//		margin-bottom: 1em;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(20 / $divider);
//		}
//
//		&:hover,
//		&:focus-visible{
//			color: $red;
//		}
//
//		&.back-to,
//		&[rel="next"]{
//			float: left;
//		}
//
//		&[rel="prev"]{
//			float: right;
//		}
//	}
//}

//simple pagination

//complex pagination

//.pagination-con{
//	display: flex;
//	justify-content: space-between;
//
//	@media (max-width: $b2){
//		justify-content: center;
//		flex-direction: column;
//		align-items: center;
//
//		.pagination.pagination.pagination{
//			margin-bottom: 20px;
//		}
//	}
//}
//
//.pagination-under{
//
//	.col-12{
//		display: flex;
//		justify-content: center;
//		align-items: center;
//
//		.pagination.pagination{
//			margin-bottom: 50px;
//		}
//	}
//}
//

.pagination-con{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4.25em;

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 7px;
		@include font-size(15);
		line-height: 45px;
		position: relative;
		z-index: 1;


		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					background-color: $blue;
					font-weight: 600;
					line-height: 45px;
				}
			}

			span,
			a{
				@include font-size(15);
				line-height: 45px;
				width: 45px;
				height: 45px;
				background-color: $brass;
				display: block;
				color: $white;
				font-weight: 600;
				padding: 0;

				&:hover,
				&:focus-visible{
					text-decoration: none;
					background-color: $blue;
				}
			}


			&.ellipse{
				color: $colour;
				font-weight: 600;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				background-color: $brass;
				line-height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				text-decoration: none;
				background-position: 50% 37%;
				background-repeat: no-repeat;
				width: 45px;
				height: 45px;

				&:hover,
				&:focus-visible{
					text-decoration: none;
				}
			}
		}

		&.next{
			
			a{
				background-image: url(../images/slide-next.svg);
				background-position: 52% 50%;

				&:hover,
				&:focus-visible{
					background-color: $blue;
				}
			}
		}

		&.prev{
			
			a{
				background-image: url(../images/slide-prev.svg);
				background-position: 48% 50%;

				&:hover,
				&:focus-visible{
					background-color: $blue;
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		13;
$social-addition:	20;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		border-radius: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $white;
		text-decoration: none;
		background-color: $grey-medium;
		padding: 0;
		font-weight: 100;
		margin: 0 9px;

		@media (max-width: 400px){
			margin: 0 10px;
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}
		
		&:hover,
		&:focus-visible{
			background-color: $brass;
			
			i{
				color: $white!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}
//
//
//		&.behance{
//
//			&:hover,
//			&:focus-visible{
//				color: $behance!important;
//			}
//		}
//
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}
//
//		&.rss{
//
//			&:hover,
//			&:focus-visible{
//				color: $rss!important;
//			}
//		}
//
//		&.slideshare{
//
//			&:hover,
//			&:focus-visible{
//				color: $slideshare!important;
//			}
//		}
//
//		&.google-plus{
//
//			&:hover,
//			&:focus-visible{
//				color: $google-plus!important;
//			}
//		}
//
		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
//
//		&.reddit{
//
//			&:hover,
//			&:focus-visible{
//				color: $reddit!important;
//			}
//		}
//
//		&.youtube{
//
//			&:hover,
//			&:focus-visible{
//				color: $youtube!important;
//			}
//		}
//
//		&.mail{
//
//			&:hover,
//			&:focus-visible{
//				color: $mail!important;
//			}
//		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	body.ie &{
		opacity: 1;
		visibility: visible;
		transition: 0s;
	}

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.link{
	text-transform: uppercase;
	@include font-size(14);
	@include line-height(14,16.8);
	display: inline-block;
	position: relative;
	font-weight: 500;
	letter-spacing: .12em;
	padding-bottom: .85em;
	padding-top: .85em;

	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		right: 2px;
		left: 2px;
		height: 2px;
		background-color: $brass;
	}

	&:hover,
	&:focus-visible{
		color: $brass;
	}
}

.image-hero-section,
.hero-section{
	position: relative;
	background-color: $blue;
	text-align: center;

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba($blue,1) 0%, rgba(white,0) 100%);
		height: 223px;
		opacity: .43;
		z-index: 9;
	}
	&:after {

		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: #464746;
		opacity: 0.7;
		top: 0;
		left: 0;
		z-index: 8;
		mix-blend-mode: multiply;
	}

	.container {
		position:relative;
		z-index: 9;
	}

	*{
		color: white;
	}

	.text{

		*{
			text-shadow: 0 0 15px rgba($black, .25);
		}
	}

	.row{
		padding-top: 196px;
		padding-bottom: 75px;
		min-height: 695px;
		align-items: center;

		@media (max-width: $b2){
			padding-top: 116px;
			min-height: 600px;
		}

		@media (max-width: $b3){
			min-height: 450px;
		}
	}
}

.image-hero-section{

	h1{
		margin-bottom: 0;
	}
}

.text-hero-section{
	position: relative;
	background-color: $blue;
	text-align: center;

	&.white{
		background-color: white;

		*{
			color: $blue;
		}

		.text{

			*{
				text-shadow: none;
			}
		}

		.row{
			align-items: flex-end;
			min-height: 456px;

			@media (max-width: $b2){
				min-height: 375px;
			}
	
			@media (max-width: $b3){
				min-height: 300px;
			}
		}
	}

	*{
		color: white;
	}

	.text{

		*{
			text-shadow: 0 0 15px rgba($black, .25);
		}
	}

	.row{
		padding-top: 196px;
		padding-bottom: 22px;
		min-height: 556px;
		align-items: center;

		@media (max-width: $b2){
			padding-top: 116px;
			min-height: 500px;
		}

		@media (max-width: $b3){
			min-height: 350px;
		}
	}
}

.blue-intro-section{
	background-color: $blue;
	padding-top: 116px;
	padding-bottom: 89px;
	text-align: center;
	
	&.bg-White {
		background-color: #fff;
		* {
			color: $blue;
		}
	
		h6{
			color: $grey-medium;
		}
	}

	@media (max-width: $b2){
		padding-top: 89px;
		padding-bottom: 89px;
	}

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	.container{
		max-width: 890px;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6{
		margin-right: auto;
		margin-left: auto;
		//width: 730px;
	}

	

	*{
		color: white;
	}

	h6{
		color: $grey-medium;

		+ h2{
			width: 830px;
		}
	}
}

.block-links-section{
	padding-top: 54px;
	padding-bottom: 54px;

	*{
		color: white;
	}

	.col-6,
	.col-12{
		margin-bottom: 13px;

		.inner{
			height: 289px;
			display: flex;
			align-items: flex-end;
			padding: 33px 44px;
			text-decoration: none;

			@media (max-width: $b2){
				padding: 15px 15px;
			}

			h3{
				margin-bottom: 0;
				padding-right: 40px;
				position: relative;
				z-index: 3;
				pointer-events: none;
				background-image: url(../images/arrow-right-white.svg);
				background-repeat: no-repeat;
				background-position: 100% 50%;
				width: 100%;

				@media (max-width: $b2){
					padding-right: 25px;
				}
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				&:hover,
				&:focus-visible{
					
					&:after{
						background-color: rgba($blue,.78);
					}
				}

				&:before{
					content: '';
					position: absolute;
					right: 0;
					left: 0;
					bottom: 0;
					background: linear-gradient(to bottom, rgba(white,0) 0%, rgba($black,1) 100%);
					height: 191px;
					opacity: .8;
				}

				&:after{
					content: '';
					position: absolute;
					right: 0;
					left: 0;
					top: 0;
					bottom: 0;
					background-color: rgba($blue,0);
					transition: $time;
				}
			}
		}
	}

	.col-6{

		@media (max-width: $b3){
			
			+ .col-6 + .col-6{
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (max-width: 460px){
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.col-6,
	.col-12{

		&:last-of-type:nth-of-type(odd){

			@media (max-width: $b2){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}

.block-links-section + .text-with-image-section{
	padding-top: 0;
}

.our-team-section + .text-with-image-section{
	padding-bottom: 94px;

	@media (max-width: $b3){
		padding-bottom: 54px;
	}
}

.text-with-image-section{
	padding-top: 69px;
	padding-bottom: 69px;

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	.col-12 *{
		max-width: 100%;
	}

	&.left{

		.image{

			@media (min-width: $b3 + 1){
				order: 2;
			}

			.img-con{

				@media (min-width: $b3 + 1){
					float: none;
				}
			}
		}

		.text{

			@media (min-width: $b3 + 1){
				order: 1;
				padding-right: 56px;
				padding-left: 6.5px;
			}
		}
	}

	.image{

		@media (min-width: $b3 + 1){
			flex: 0 0 48.5%;
			max-width: 48.5%;
		}

		.img-con{
			height: 496px;
			
			@media (min-width: $b1 + 1){
				width: calc(100% + ((100vw - #{$base-width})/2));
				max-width: calc(100% + ((100vw - #{$base-width})/2));
			}

			@media (max-width: $b1){
				
				@media (min-width: $b3 + 1){
					width: calc(100% + 13px);
					max-width: calc(100% + 13px);
				}
			}
			
			@media (min-width: $b3 + 1){
				float: right;
			}

			@media (max-width: $b3){
				margin-left: -13px;
				width: calc(100% + 26px);
				max-width: calc(100% + 26px);
				height: auto;
				padding-top: 76%;
			}
		}
	}

	.row{
		align-items: center;
	}

	.text{
		text-align: center;
		padding-top: 14px;

		@media (max-width: $b3){
			padding-top: 1.5em;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 51.5%;
			max-width: 51.5%;
			padding-left: 56px;
		}

		.inner{
			margin: 0 auto;
			width: 560px;
			width: 543px;
		}
	}

	.btn-con{
		margin-top: 2.5em;
	}
}

.quotes-section{
	min-height: 413px;
	padding-top: 55px;
	padding-bottom: 45px;
	position: relative;
	text-align: center;
	display: flex;

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($brass,.95);
	}

	blockquote{
		margin-right: auto;
		margin-left: auto;
		width: 960px;
		max-width: calc(100% - 80px)!important;
	}

	*{
		color: white;
	}

	.swiper-button-next,
	.swiper-button-prev{
		background-color: transparent;

		svg{

			*{
				fill: $grey;
				transition: $time;
			}
		}

		&:hover,
		&:focus-visible{
			
			svg{

				*{
					fill: $white;
				}
			}
		}
	}

	.quote-slide{

		.swiper-slide{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			min-height: 313px;
		}

		.inner{
			width: 100%;
		}
	}
}

.latest-news-and-articles-section{
	padding-top: 62px;
	padding-bottom: 95px;

	.container{
		max-width: 1340px;
	}

	.title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.35em;

		@media (max-width: $b3){
			flex-wrap: wrap;
			
			h2{
				width: 100%;
			}
		}

		h2{
			margin-bottom: 0;
		}
	}

	.link{
		@include font-size(15);
		font-weight: 600;
		letter-spacing: .08em; 
	}

	.latest-slide{
		overflow: visible;

		.swiper-button-next,
		.swiper-button-prev{
			top: auto;
			bottom: -42px;
			transform: none;
		}

		.swiper-button-prev{
			right: 42px;
			left: auto;

			@media (max-width: $b2){
				right: 34px;
			}
		}

		.swiper-button-next{
			right: 0;
		}

		.swiper-scrollbar{
			bottom: -27px;
			width: calc(100% - 123px);
			background-color: $grey-light;

			@media (max-width: $b2){
				bottom: -30px;
				width: calc(100% - 68px);
			}

			.swiper-scrollbar-drag{
				background-color: $blue;
			}
		}
	}

	@media (min-width: $b3 + 1){
		
		.n-item{
			margin-bottom: 18px;
	
			.img-con{
				margin-bottom: 1.55em;
			}
	
			.date{
				margin-bottom: .7em;
			}
		}
	}
}

.our-values-section{
	padding-top: 59px;
	padding-bottom: 59px;

	&.hide{

		.swiper-scrollbar,
		.swiper-button-next,
		.swiper-button-prev{
			display: none;
		}

		.text-side-side{
			margin-bottom: 33px;
		}
	}

	.container{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: -61px;
			left: 4px;
			right: 4px;
			bottom: -55px;
			background-color: $blue;
			border-radius: 10.5227px;
		}
	}

	*{
		color: white;
	}

	.title{
		text-align: center;
		margin-bottom: 1.4em;
	}

	.swiper-slide {
		height: auto!important;
	}

	.nav-slide{
		//height: 313px;

		@media (max-width: $b3){
			height: 271px;
		}

		h2{
			margin-bottom: 0;
			cursor: pointer;
		}
	}

	.nav-side-side{
		padding-right: 15px;
		padding-left: 15px;

		@media (min-width: $b2 + 1){
			padding-right: 30px;
			padding-left: 90px;
		}

		@media (min-width: $b1 + 1){
			padding-right: 90px;
		}

		.swiper-slide{
			opacity: .25;

			@media (max-width: $b1){
				
				h2{
					@include font-size(31);
				}
			}

			@media (max-width: $b3){
				text-align: center;
					
				h2{
					@include font-size(26);
				}
			}
		}

		.swiper-slide-thumb-active{
			opacity: 1;
		}
	}

	.text-side-side{
		text-align: center;
		margin-bottom: 85px;
		padding-right: 15px;
		padding-left: 15px;

		@media (min-width: $b3 + 1){
			border-left: 2px solid $grey-medium;
		}

		@media (min-width: $b2 + 1){
			padding-right: 55px;
			padding-left: 55px;
		}

		@media (min-width: 1200px + 1){
			padding-right: 110px;
			padding-left: 110px;
		}

		.swiper-scrollbar{
			width: calc(#{$base-width} - 205px - 90px);
			max-width: calc(#{$base-width} - 205px - 90px);
			margin-left: calc(100px - 90px - 157%);

			@media (max-width: 1358px){
				width: calc(100vw - 205px - 90px);
				max-width: calc(100vw - 205px - 90px);
				margin-left: calc(100px - 90px - 50vw - 26px);
			}

			@media (max-width: $b2){
				width: calc(100vw - 120px);
				max-width: calc(100vw - 120px);
				margin-left: calc(100px - 65px - 50vw - 26px);
			}

			@media (max-width: $b3){
				margin-left: 5px;
			}
		}

		.swiper-button-next,
		.swiper-button-prev{
			top: auto;
			bottom: -98px;

			&:hover,
			&:focus-visible{
				background-color: $blue-light;
			}
		}

		.swiper-button-next{
			right: -25px;

			@media (max-width: $b2){
				right: 4px;
			}
		}

		.swiper-button-prev{
			left: auto;
			right: 27px;

			@media (max-width: $b2){
				right: 38px;
			}
		}

		.text-slide{
			overflow: visible;

		}

		.swiper-wrapper{
			pointer-events: none;
		}

		.swiper-slide{
			opacity: 0;
			transition: 0s;
		}

		.swiper-slide-active{
			opacity: 1;
			transition: .3s .3s;
		}

		p{
			font-family: $alt-font;
			@include font-size(23);
			@include line-height(23,34.2779);
			letter-spacing: .01em;

			@media (max-width: $b2){
				@include font-size(20);
			}
		}

		.swiper-slide{
			height: 313px;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: $b3){
				height: auto;
			}
		}

		.inner{
			margin-top: 1em;

			*:last-child{
				margin-bottom: 0;
			}
		}
	}
}

.our-team-section{
	padding-top: 85px;
	padding-bottom: 28px;

	@media (max-width: $b3){
		padding-top: 54px;
	}

	.title-con{
		margin-bottom: 1.1em;

		@media (max-width: $b3){
			margin-bottom: 3.1em;
		}

		h2{
			padding-right: 91px;

			@media (max-width: $b2){
				padding-right: 68px;
			}

			@media (max-width: $b3){
				padding-right: 0;
			}
		}
	}

	.team-slide{
		overflow: visible;

		.inner{
			width: 100%;
			text-align: center;
		}

		.img-con{
			width: 100%;
			padding-top: 107.5%;
			margin-bottom: 1.6em;
		}

		.role{
			color: $grey-medium;
			@include font-size(14);
			font-weight: 500;
			text-transform: uppercase;
		}

		h3{
			margin-bottom: .3em;
		}

		.swiper-button-next,
		.swiper-button-prev{
			top: -107px;
			transform: none;

			@media (max-width: $b3){
				top: -58px;
			}
		}

		.swiper-button-prev{
			right: 43px;
			left: auto;

			@media (max-width: $b2){
				right: 34px;
			}
		}

		.swiper-button-next{
			right: 0;
		}
	}
}

.text-with-side-section{
	padding-top: 71px;
	padding-bottom: 57px;

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	&.right{

		.col-md-4{

			hr{
				margin-right: 0;
				margin-left: 0;
			}

			h2 + hr {
				margin-top: -.5em;
			}
		}
	}

	.col-md-4{

		@media (min-width: $b2 + 1){
			padding-right: 90px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 39.63333%;
			max-width: 39.63333%;
		}
	}

	.col-md-8{
		padding-top: .5em;

		@media (min-width: $b2 + 1){
			padding-right: 50px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 60.36667%;
			max-width: 60.36667%;
		}
	}
}

.recent-developments-and-investments-section{
	padding-top: 70px;
	padding-bottom: 62px;
	min-height: 600px;
	background-color: $grey-lighter;

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	.title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.56em;
		
		@media (max-width: $b3){
			flex-wrap: wrap;

			h2{
				width: 100%;
			}
		}

		h2{
			margin-bottom: 0;
		}
	}

	.link{
		@include font-size(15);
		font-weight: 600;
		letter-spacing: .08em;
		//margin-right: 107px;
	}
}

.brass-text-section{
	background-color: $brass;
	padding-top: 78px;
	padding-bottom: 60px;
	text-align: center;

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	*{
		color: white;
	}

	h2{
		margin-bottom: .2em;
	}

	.btn{
		margin-top: 1.2em;
	}

	h3,
	h4,
	h5{
		color: $blue;
	}
}

.form-section{
	padding-top: 68px;
	padding-bottom: 30px;

	@media (max-width: $b2){
		padding-top: 54px;
	}

	.text{
		text-align: center;
		margin-bottom: .95em;
	}

	.container{
		max-width: 894px;
	}

	&.brass{
		background-color: $brass;
		padding-top: 73px;
		padding-bottom: 25px;

		@media (max-width: $b2){
			padding-top: 54px;
		}

		.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
		input:focus-visible,
		textarea:focus-visible,
		select:focus-visible,
		input[name][name][name]:focus-visible,
		textarea[name][name][name]:focus-visible,
		select[name][name][name]:focus-visible{
			border-color: darken($brass-form, 5%)!important;
		}

		.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
		input.mage-error:focus-visible,
		select.mage-error:focus-visible,
		textarea.mage-error:focus-visible,
		.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
		li.gfield.error input,
		.gfield_error input,
		.gfield_error select,
		.gfield_error .dk-select,
		.gfield_error textarea,
		input.mage-error,
		select.mage-error,
		textarea.mage-error{
			border-color: $red!important;
		}

		.text{
			margin-bottom: .45em;

			*{
				color: white;
			}
		}

		.wpcf7-form.submitting{

			&:before{
				border-color: $blue;
			}
		}

		form{

			::-webkit-input-placeholder {
				color: $white;
			}
			 
			:-moz-placeholder { /* Firefox 18- */
				color: $white;
			}
			
			::-moz-placeholder {  /* Firefox 19+ */
				color: $white;
			}
			
			:-ms-input-placeholder {
				color: $white;
			}

			label{
				color: white;
			}

			input[type="radio"] + span, 
			input[type="radio"] + .wpcf7-list-item-label, 
			input[type="radio"] + label, 
			input[type="checkbox"] + span, 
			input[type="checkbox"] + .wpcf7-list-item-label, 
			input[type="checkbox"] + label{
				color: white;

				&:before{
					border-color: $brass-form;
					background-color: $brass-form;
				}

				&:after{
					border-color: $white;
					background-color: $white;
				}

				a{

					&:hover,
					&:focus-visible{
						color: white;
					}
				}
			}

			[type="submit"]{
				background-color: $blue;
				border-color: $blue;
			}
		}

		input, 
		input.input-text, 
		textarea, 
		select{
			border-color: $brass-form;
			background-color: $brass-form;
			color: white;
		}

		option{
			color: $colour;
		}

	}
}

.our-offices-section{
	padding-top: 58px;
	padding-bottom: 96px;

	@media (max-width: $b3){
		padding-top: 54px;
	}

	.title{
		text-align: center;
		margin-bottom: -.4em;
	}

	.office-slide{
		overflow: visible;

		.swiper-button-next,
		.swiper-button-prev{
			top: auto;
			bottom: -42px;
			transform: none;

			@media (max-width: $b2){
				bottom: -44px;
			}
		}

		.swiper-button-prev{
			right: 52px;
			left: auto;

			@media (max-width: $b2){
				right: 34px;
			}
		}

		.swiper-button-next{
			right: 0;
		}

		.swiper-scrollbar{
			bottom: -27px;
			width: calc(100% - 133px);
			background-color: $grey-light;

			@media (max-width: $b2){
				bottom: -31px;
				width: calc(100% - 68px);
			}

			.swiper-scrollbar-drag{
				background-color: $blue;
			}
		}
	}

	h3{
		margin-bottom: 1em;
	}

	.info{
		font-weight: 500;

		a{
			font-weight: 500;

			&[href^="mailto:"]{
				color: $brass;
				text-decoration: underline;

				&:hover,
				&:focus-visible{
					color: darken($brass, 10%);
				}
			}
		}

		&:last-of-type{
			margin-bottom: 1.6em;
		}
	}

	address{
		line-height: 1.5;
		margin-bottom: 1.6em;
	}
	

	.acf-map{
		width: 100%;
		height: 216px;
		margin-bottom: 2.2em;
		background-color: $grey-lighter;

		*{
			max-width: none;
		}

		[target="_blank"],
		.gm-style-cc,
		.gmnoprint,
		.gm-fullscreen-control{
			display: none!important;
		}

		img{
			max-width: inherit !important;
		}
	}
}

.news-section{
	padding-top: 40px;
	padding-bottom: 75px;

	.filter-con{
		display: flex;
		justify-content: center;
		margin-bottom: 7.9em;

		@media (max-width: $b2){
			margin-bottom: 5em;
		}

		@media (max-width: $b3){
			margin-bottom: 2.5em;
		}

		select{
			width: auto;
			min-width: 396px;
			color: $grey;
			margin-bottom: 0;

			@media (max-width: $b3){
				min-width: 0;
			}
		}
	}

	.container{
		max-width: 1366px;
	}

	@media (min-width: $b3 + 1){
		
		.container,
		.col-12{
			padding-right: 17.5px;
			padding-left: 17.5px;
		}

		.row{
			margin-right: -17.5px;
			margin-left: -17.5px;
		}
	}

	.n-item{
		margin-bottom: 38px;
	}

	.f-item{
		margin-bottom: 50px;
	}
}

.f-item{

	.inner{
		width: 100%;
		position: relative;
		position: relative;
		padding-top: 45.4%;
		min-height: 447.64px;

		> a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			letter-spacing: .01em;

			&:hover,
			&:focus-visible{
				
				~ .text h2{
					color: $brass;
				}

				~ .back{
					background-color: rgba($blue,.78);
				}
			}
		}

		.back{
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: rgba($blue, 0);
			transition: $time;
		}

		.cats{
			position: absolute;
			top: 44px;
			left: 46px;
			right: 46px;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: $b2){
				top: 15px;
				left: 15px;
				right: 15px;
			}

			a{
				background-color: white;
				margin-right: 11.5px;
				margin-bottom: 11.5px;
				font-weight: 500;
				padding: 9px 23px;
				@include font-size(14);
				text-transform: uppercase;
				position: relative;
				z-index: 3;
				color: $colour;
				letter-spacing: .12em;
				box-shadow: 0 0 11.5px rgba(black, .1);

				@media (max-width: $b2){
					padding: 7px 9px;
					@include font-size(12);
				}

				@media (max-width: $b3){
					padding: 4px 9px;
				}

				&:hover,
				&:focus-visible{
					background-color: $brass;
				}
			}
		}
	}

	.text{
		position: absolute;
		bottom: 48px;
		width: 443px;
		max-width: calc(100% - 46px - 46px);
		left: 46px;
		background-color: white;
		padding: 40px 50px 47px;
		box-shadow: 0 0 11.5px rgba(black, .1);
		z-index: 2;
		pointer-events: none;

		@media (max-width: $b2){
			bottom: 15px;
			left: 15px;
			padding: 9px 9px;
			max-width: calc(100% - 15px - 15px);
		}

		@media (max-width: $b3){
			padding: 9px 9px;
		}

		h2{
			margin-bottom: 0;
			line-height: 1.105;
			transition: $time;
		}
	}
	
	.date{
		@include font-size(14);
		text-transform: uppercase;
		color: $grey-medium;
		font-weight: 500;
		margin-bottom: 2.75em;

		@media (max-width: $b2){
			margin-bottom: 1em;
			@include font-size(12);
		}
	}
}

.n-item{

	.inner{
		width: 100%;
		position: relative;

		> a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			letter-spacing: .01em;

			&:hover,
			&:focus-visible{
				
				~ h3{
					color: $brass;
				}

				~ .img-con{

					&:after{
						background-color: rgba($blue,.78);
					}
				}
			}
		}
	}

	h3{
		transition: $time;
	}

	.thumbnail{
		position: relative;

		img{
			object-fit: cover;
			object-position: 50% 50%;
		}

		span{
			position: absolute;
			top: 23px;
			left: 23px;
			display: inline-flex;
			flex-wrap: wrap;
			background-color: white;
			margin-right: 11.5px;
			margin-bottom: 11.5px;
			font-weight: 500;
			padding: 9px 23px;
			@include font-size(14);
			text-transform: uppercase;
			z-index: 3;
			color: $colour;
			letter-spacing: .12em;
			box-shadow: 0 0 11.5px rgba(black, .1);
			pointer-events: none;

			@media (max-width: $b2){
				padding: 7px 9px;
				@include font-size(12);
			}

			@media (max-width: $b3){
				padding: 4px 9px;
			}
		}
	}

	.img-con{
		position: relative;
		padding-top: 61.9%;
		margin-bottom: 1.7em;
		background-color: $brass;

		&:after{
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: rgba($blue, 0);
			transition: $time;
		}

		.cats{
			position: absolute;
			top: 23px;
			left: 23px;
			right: 23px;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: $b2){
				top: 15px;
				left: 15px;
				right: 15px;
			}

			a{
				background-color: white;
				margin-right: 11.5px;
				margin-bottom: 11.5px;
				font-weight: 500;
				padding: 9px 23px;
				@include font-size(14);
				text-transform: uppercase;
				position: relative;
				z-index: 3;
				color: $colour;
				letter-spacing: .12em;
				box-shadow: 0 0 11.5px rgba(black, .1);

				@media (max-width: $b2){
					padding: 7px 9px;
					@include font-size(12);
				}

				@media (max-width: $b3){
					padding: 4px 9px;
				}

				&:hover,
				&:focus-visible{
					background-color: $brass;
				}
			}
		}
	}

	.date{
		@include font-size(14);
		text-transform: uppercase;
		color: $grey-medium;
		font-weight: 500;
		margin-bottom: 1em;

		@media (max-width: $b2){
			@include font-size(12);
		}
	}
}

.news-article-section{
	padding-top: 60px;
	padding-bottom: 66px;

	@media (max-width: $b3){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	@media (max-width: $b2){
		padding-top: 54px;
		padding-bottom: 54px;
	}

	.container{
		max-width: 1284px;
	}

	.info{
		@include font-size(14);
		text-transform: uppercase;
		color: $grey-medium;
		font-weight: 500;
		margin-bottom: 1.4em;
		letter-spacing: .13em;

		@media (max-width: $b2){
			@include font-size(12);
		}

		a{
			text-decoration: underline;
			color: $brass;

			&:hover,
			&:focus-visible{
				color: $blue;
			}

			+ a{
				
				&:before{
					content: ', ';
				}
			}
		}
	}

	.text{

		@media (min-width: $b3 + 1){
			flex: 0 0 74.86667%;
			max-width: 74.86667%;
			padding-right: 103px;
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: -24px;
				bottom: 5px;
				right: 45px;
				width: .5px;
				background-color: #c7c7c7;
			}
		}
	}

	.share{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 3.95em;

		@media (max-width: $b3){
			margin-top: 1.5em;
			margin-bottom: 1.5em;
		}

		h3{
			margin-bottom: 0;
			margin-right: 2.6em;
			margin-top: 5px;
			margin-bottom: 5px;

			@media (max-width: $b3){
				margin-right: 12.5px;
			}
		}

		.btn{
			min-width: 0;
			padding: 13.5px 24px;
			font-size: 15px!important;
			font-size: 1.5rem!important;
			font-weight: 600;
			letter-spacing: .09em;
			margin-right: 1.9em;
			margin-top: 5px;
			margin-bottom: 5px;

			@media (max-width: $b3){
				padding: 13.5px 13.5px;
				min-width: 48px;
				margin-right: 12.5px;
			}

			&:last-of-type{
				margin-right: 0;
			}

			svg{
				width: auto;
				height: 17px;

				+ span{
					margin-left: 17px;

					@media (max-width: $b3){
						display: none;
					}
				}
			}
			
		}
	}

	.recent{
		padding-top: .3em;

		@media (min-width: $b3 + 1){	
			flex: 0 0 25.13333%;
			max-width: 25.13333%;
		}

		@media (min-width: $b1 + $gap){
			padding-right: 0;
		}

		h2{
			margin-bottom: .62em;
		}

		.n-item{
			margin-bottom: 56px;

			&:last-of-type{
				margin-bottom: 0;
			}

			.cats{
				display: none;
			}

			.img-con{
				margin-bottom: 1em;
			}

			.date{
				margin-bottom: .45em;
			}
		}
		
	}
}

.areas-we-cover-section{
	padding-top: 58px;
	padding-bottom: 38px;

	@media (max-width: $b3){
		padding-top: 54px;
	}

	*{
		color: white;
	}

	.awc-item{
		margin-bottom: 17px;

		.inner{
			height: 289px;
			display: flex;
			align-items: flex-end;
			padding: 33px 44px;
			text-decoration: none;

			@media (max-width: $b2){
				padding: 15px 15px;
			}

			h3{
				margin-bottom: 0;
				padding-right: 40px;
				position: relative;
				z-index: 3;
				pointer-events: none;
				background-image: url(../images/arrow-right-white.svg);
				background-repeat: no-repeat;
				background-position: 100% 50%;
				width: 100%;

				@media (max-width: $b2){
					padding-right: 25px;
				}
			}

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				&:hover,
				&:focus-visible{
					
					&:after{
						background-color: rgba($blue,.78);
					}
				}

				&:before{
					content: '';
					position: absolute;
					right: 0;
					left: 0;
					bottom: 0;
					background: linear-gradient(to bottom, rgba(white,0) 0%, rgba($black,1) 100%);
					height: 191px;
					opacity: .8;
				}

				&:after{
					content: '';
					position: absolute;
					right: 0;
					left: 0;
					top: 0;
					bottom: 0;
					background-color: rgba($blue,0);
					transition: $time;
				}
			}
		}
	}
}

.standard-text-section{
	padding-top: 58px;
	padding-bottom: 43px;

	@media (max-width: $b3){
		padding-top: 54px;
	}

	.container{
		max-width: 1050px;
	}

	h2{
		margin-bottom: 0.7em;
	}

	h3{
		margin-bottom: 1.1em;
	}

	p{

		+ h3{
			margin-top: 1.7em;
		}
	}
}

.brass-box-section{
	padding-bottom: 68px;

	@media (max-width: $b3){
		padding-bottom: 54px;
	}

	.container{
		max-width: 1334px;
		text-align: center;
	}

	.row{
		background-color: $brass;
		align-items: center;
		min-height: 330px;
		padding-top: 33px;
		padding-bottom: 25px;
	}

	h1,
	h2{
		color: white;
	}

	h2{
		margin-bottom: .2em;
	}

	.btn-con{
		margin-top: 1.6em;
	}
}

.properties-in-section{
	padding-top: 70px;
	padding-bottom: 62px;
	min-height: 687px;
	background-color: $grey-lighter;

	
	

	.title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.56em;

		@media (max-width: $b3){
			flex-wrap: wrap;

			h2{
				width: 100%;
			}
		}

		h2{
			margin-bottom: 0;
		}
	}

	.link{
		@include font-size(15);
		font-weight: 600;
		letter-spacing: .08em;
		margin-right: 107px;
	}
}


.content-image-box {
	.container {
		width: calc(100% - 13px);
	}
	&__box {
		background-color: $blue;
		border-radius: 10.5227px;
		overflow: hidden;
		
		@media(max-width:768px) {
			.col-content {
				order: 2;
			}
		}
		
		.text {
			padding: 90px 20px;
			max-width: 450px;
			margin: 0 auto;
			text-align: center;
			@media(max-width:768px) {
				padding: 60px 20px;
			}
			* {
				color: white;
			}
			
			.btn-con {
				padding-top: 20px;
				.btn {
					&:hover {
						border-color: $brass !important;
					}
				}
			}
		}
		
		.img-wrap {
			height: 100%;
			width: 100%;
			.bg-img {
				height: 100%;
				width: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: 50% 50%;
				@media(max-width:768px) {
					padding-bottom: 66%;
				}
			}
		}
	}
}




.development-listings {
	padding: 80px 0;
	background-color: #F0F0F0;
	h2 {
		text-align: center;
		margin-bottom: 45px;
	}
	
	.row {
		margin: 0 -15px;
		.col-12 {
			padding: 0 15px;
			margin-bottom: 30px;
		}
	}
	
	.development-item {
		height: 100%;
		&-link {
			display: block;
			height: 100%;
			background-color: #fff;
			&:hover {
				.img-wrap {
					&:before {
						opacity: 1;
					}
				}
			}
			.img-wrap {
				height: 260px;
				overflow: hidden;
				position: relative;
				z-index: 10;
				// &:before {
				// 	content: '';
				// 	height: 100%;
				// 	width: 100%;
				// 	position: absolute;
				// 	top: 0;
				// 	left: 0;
				// 	z-index: 10;
				// 	background-color: rgba(50, 62, 72, 0.84);
				// 	background-repeat: no-repeat;
				// 	background-position: 50% 50%;
				// 	background-image: url('../images/search-white.svg');
				// 	transition: .3s;
				// 	opacity: 0;
				// 	position: none;
				// }
				img {
					object-fit: cover;
					min-width: 100%;
					min-height: 100%;
					height: 260px;
					width: auto;
				}
			}
			
			.text-wrap {
				padding: clamp(20px, 2.5vw, 30px);
				background-color: #fff;
				
				.text {
					* {
						font-weight: 400;
					}
					h3 {
						font-weight: 600;
					}
				}
				
				.dev-info {
					li {
						list-style: none;
						padding-left: 0;
						font-weight: 600;
						&:before {
							display: none;
						}
						span {
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}



.fancybox-bg {
	background-color: rgba(50, 62, 72, 1) !important;
}





.container{
	width: calc(100% - #{$grid-gutter-width});
}

@import 'propertyhive/style';

.posts-slider {
	overflow: hidden;
	.img-con {
		margin-bottom: 0;
	}
	&__wrapper {
		background: #ffffff;
		padding: 30px;
	}



}
.recent-developments-and-investments-section {
	.title {
		width: 100%;
		display: block;
	}
}
.recent-investments__header {

		display: flex;
		flex-wrap:wrap;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 540px){
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
		h2 {
			margin-bottom:0;
		}


	.link {
		margin-right: 30px;
	}
	.misc-wrapper {
		margin-left: auto;
		margin-right: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 540px){
			margin:0;
			margin-top: 2rem;
			justify-content: space-between;
			width: 100%;

		}
	}

}

.the-button-wrapper {
	position: relative;
	display: flex;
	div {
		transform: none;
		position: relative;
		top:0;
		left:0;
		right:0;
		&:first-of-type {
			margin-right: 4px;
		}
	}
}

.text-hero-form {
	position: relative;
  background-color: $blue;
  text-align: center;
	padding: 280px 0 0;

	&__top-panel {
		max-width: 920px;
		margin: 0 auto 60px;
	}
	
	&__title {
		font-size: 59px;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 1.2;
		margin: 0 0 30px;
		letter-spacing: -0.01em;
	}

	&__text {

		& * {
			font-family: $font-dm;
		}

		p, li, a {
			font-size: 24px;
			font-weight: 400;
			color: #ffffff;
			line-height: 1.5;
			letter-spacing: -0.01em;
		}
	}

	&__bottom-panel {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			right: 50%;
			width: 100vw;
			height: 50%;
			margin-left: -50vw;
			margin-right: -50vw;
			background-color: #ffffff;
		}
	}

	&__form-wrap {
		padding: 45px 70px 52px;
		background-color: #ffffff;
		box-shadow: 0 0 15px rgba(0,0,0, 0.25);
		border-radius: 22px;
		max-width: 1156px;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}

	&__form-title {
		font-size: 45px;
		font-weight: 400;
		color: $blue;
		text-align: center;
		letter-spacing: -0.01em;
		margin: 0 0 35px;
	}

	form .small {
		width: calc(33.333% - 28px);
	}

	.form .small br {
		display: none;
	}

	.form label {
		text-align: left;
	}
	
	.form input {

		&::placeholder {
			color: #8E959A;
			opacity: 1;
		}
	}

	.form .bottom-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.form .bottom-wrap .large {
		padding-right: 20px;
	}

	.form .bottom-wrap .wpcf7-form-control,
	.form .bottom-wrap p {
		margin: 0;
	}

	.form .bottom-wrap label,
	.form .bottom-wrap label span {
		font-size: 16px;
	}

	.form .bottom-wrap input[type="submit"] {
		min-width: 141px;
		padding: 11px 10px;
	}

	@media screen and (max-width: 1200px) {

		&__form-wrap {
			padding: 45px 50px 52px;
			max-width: 1156px;
		}

		form .small {
			width: calc(33.333% - 15px);
		}

		.form .bottom-wrap {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}

		.form .bottom-wrap .large {
			padding: 0;
			margin: 0 0 40px;
		}
	}

	@media screen and (max-width: 991px) {
		padding: 145px 0 0;
	}

	@media screen and (max-width: 960px) {

		&__title {
			font-size: 3.2rem;
		}
	
		&__text {
	
			p, li, a {
				font-size: 15px;
			}
		}

		&__form-title {
			font-size: 26px;
		}

		&__form-wrap {
			padding: 40px 24px;
			border-radius: 8px;
		}

		form .small {
			width: 100%;
		}

		.form .bottom-wrap label,
		.form .bottom-wrap label span {
			font-size: 15px;
		}
	}

	@media screen and (max-width: 600px) {

		&__form-wrap {
			padding: 32px 14px;
		}
	}
}

.single-current-development {

	.addtoany_share_save_container {
		display: none !important;
	}
}

.image_slider {
	position: relative;
	background-color: #F0F0F0;
	padding-top: 50px;
	padding-bottom: 50px;

	.swiper-button-prev {
		
		@media screen and (min-width:1600px) {
			left: 100px;
		}
	}

	.swiper-button-next {
		
		@media screen and (min-width:1600px) {
			right: 100px;
		}
	}

	&--item {
		
		a {
			display: block;
			padding-bottom: 56.25%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			&:hover {
				&:before {
					opacity: 1;
				}
			}

			&:before {
				content: '';
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
				background-color: rgba(50, 62, 72, 0.84);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-image: url('../images/search-white.svg');
				transition: .3s;
				opacity: 0;
				position: none;
			}
		}
	}
}

.table {
	padding-bottom: 50px;

	&_main {
		position: relative;
		overflow: auto;
		width: 100%;
	}

	.tablepress {
		margin-bottom: 0 !important;

		thead {

			tr {

				th {
					background-color: #323e48;
					color: #fff;
					padding: 15px;
					text-align: center;

					@media screen and (max-width:767px) {
						padding: 10px;
					}
				}
			}
		}

		tbody {
			border: 2px solid #F0F0F0;
			border-top: none !important;

			tr {

				td {
					padding: 15px;
					text-align: center !important;
					border: none !important;

					@media screen and (max-width:767px) {
						padding: 10px;
					}
				}

				&.odd {
					
					td {
						background-color: #F0F0F0;
					}
				}
			}
		}
	}
}