//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerWidth: 33px;
$outerHeight: 33px;

$lineHeight: 3px;
$lineWidth: 33px;
$gap: 8px;
$radius: 0px;

$outerWidthM: 24px;
$outerHeightM: 24px;

$lineHeightM: 2.5px;
$lineWidthM: 24px;
$gapM: 5px;
$radiusM: 0px;

$topLineColour: $white;
$topLineColourOpen: $blue;

$middleLineColour: $white;
$middleLineColourOpen: transparent;

$bottomLineColour: $white;
$bottomLineColourOpen: $blue;

//@media (max-width: $b2){
//
//	.main-wrapper{
//
//		section{
//			overflow: hidden;
//			max-width: 375px;
//		}
//	}
//}

.burger{
	height: $outerHeight;
	width: $outerWidth;
	border-radius: 0;
	line-height: $outerHeight;
	padding: 0;
	-webkit-perspective: 900000px;
	perspective: 900000px;
	z-index: 1;
	pointer-events: auto;
	position: relative;
	transition: $time;
	margin-left: 98px;
	color: white;
	@include font-size(16);
	font-weight: 500;
	letter-spacing: .07em;
	
	@media (min-width: 1358px + 1){
		margin-right: 9px;
	}

	@media (max-width: $b2){
		@include font-size(14);
		margin-left: 19px;
		height: $outerHeightM;
		width: $outerWidthM;
		line-height: $outerHeightM;
	}

	&:before{
		content: 'MENU';
		position: absolute;
		top: 50%;
		right: calc(100% + 18px);
		transform: translateY(-50%);
		width: auto;
		max-width: initial;

		@media (max-width: $b2){
			display: none;
			right: calc(100% + 5px);
		}
	}

	.dark.dark.dark:not(.menu-open) body:not(.scroll-up):not(.scroll-down) &{
		
		&:before{
			color: $blue;
		}
	}

	@media (max-width: $b2){
		display: block;
		margin-bottom: 0;
	}

	&:hover{
		cursor: pointer;
	}

	.line1{
		width: $lineWidth;
		max-width: 100%;
		height: $lineHeight;
		background-color: $middleLineColour;
		position: absolute;
		top: (($outerHeight / 2) - ($lineHeight / 2));
		left: 50%;
		border-radius: $radius;
		transform: translateX(-50%);
		background-position: center center;

		.dark.dark.dark:not(.menu-open) body:not(.scroll-up):not(.scroll-down) &{
			background-color: $blue;
		}

		@media (max-width: $b2){
			width: $lineWidthM;
			height: $lineHeightM;
			top: (($outerHeightM / 2) - ($lineHeightM / 2));
		}

		&:before,
		&:after{
			width: $lineWidth;
			max-width: 100%;
			height: $lineHeight;
			position: absolute;
			content: '';
			left: 0px;
			border-radius: $radius;
			transform-origin: center center;
			background-position: center center;
			transition: $time;

			.dark.dark.dark:not(.menu-open) body:not(.scroll-up):not(.scroll-down) &{
				background-color: $blue;
			}

			@media (max-width: $b2){
				width: $lineWidthM;
				height: $lineHeightM;
				border-radius: $radiusM;
			}
		}

		&:before{
			transform: translateY(-#{$gap + $lineHeight});
			background-color: $topLineColour;

			@media (max-width: $b2){
				transform: translateY(-#{$gapM + $lineHeightM});
			}
		}

		&:after{
			transform: translateY(#{$gap + $lineHeight});
			background-color: $bottomLineColour;

			@media (max-width: $b2){
				transform: translateY(#{$gapM + $lineHeightM});
			}
		}
	}

	&.close{

		&:before{
			transition: color .1s .25s;
		}

		.line1{
			background-color: $middleLineColour;
			transition: visibility .1s .2s, background-color .1s .2s;

			&:before{
				transform: translateY(-#{$gap + $lineHeight});
				background-color: $topLineColour;
				visibility: visible;
				animation: before .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					transform: translateY(-#{$gapM + $lineHeightM});
					animation: beforeM .5s linear;
				}
			}

			&:after{
				transform: translateY(#{$gap + $lineHeight});
				background-color: $bottomLineColour;
				visibility: visible;
				animation: after .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					transform: translateY(#{$gapM + $lineHeightM});
					animation: afterM .5s linear;
				}
			}

			@keyframes before {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gap + $lineHeight});}
			}

			@keyframes after {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gap + $lineHeight});}
			}

			@keyframes beforeM {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gapM + $lineHeightM});}
			}

			@keyframes afterM {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gapM + $lineHeightM});}
			}
		}
	}

	&.open{
		z-index: 112;

		&:before{
			color: $blue;
			transition: color .1s .25s;

			@media (max-width: $b2){	
				display: block;
			}
		}

		.line1{
			transform-origin: 50% 50%;
			transition: visibility .1s .25s, background-color .1s .25s;
			background-color: $middleLineColourOpen;

			.dark.dark.dark:not(.menu-open) body:not(.scroll-up):not(.scroll-down) &{
				background-color: $blue;
			}

			&:before{
				transform: rotate(45deg) translateY(0);
				background-color: $topLineColourOpen;
				visibility: visible;
				animation: before-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					animation: before-openM .5s linear;
				}
			}

			&:after{
				transform: rotate(-45deg) translateY(0);
				background-color: $bottomLineColourOpen;
				visibility: visible;
				animation: after-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
				
				@media (max-width: $b2){
					animation: after-openM .5s linear;
				}
			}

			@keyframes before-open {
				0% {transform: translateY(-#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-open {
				0% {transform: translateY(#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}

			@keyframes before-openM {
				0% {transform: translateY(-#{$gapM + $lineHeightM});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-openM {
				0% {transform: translateY(#{$gapM + $lineHeightM});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}
		}
	}
}
