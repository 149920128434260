//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: absolute;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	// .home &{
	// 	background-color: transparent;
	// }

	@media (max-width: $b2){
		z-index: 111;
		margin-bottom: -1px;
	}

	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $b2){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $b2 + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 175.4015px;
	transition: 0s;
	margin-top: 50px;
	margin-bottom: -34px;

	@media (max-width: $b2){
		width: 153.4312px;
		margin: 0;
	}

	.dark body:not(.scroll-up):not(.scroll-down) &{

		svg{

			g{
				*{
					fill: $blue;
				}
			}
		}
	}

	svg{

		g{
			*{
				fill: $white;
			}
		}
	}

	svg {
		#Group_78 {
			fill: $brass!important;
		}
	}

	svg,
	img{
		display: block;
		width: 100%;

		&:nth-of-type(2){
			display: none;
		}

		@media (max-width: $b2){

			&:nth-of-type(1){
				display: none;
			}
			
			&:nth-of-type(2){
				display: block;
			}
		}
	}
}

.menu-top-outer{
	transition: $time;

	@media (max-width: $b2){
		
		.menu-open &{
			background-color: $blue;
		}
	}
	
	@media (max-width: $b2){
		padding-top: 37px;
		padding-bottom: 37px;
		min-height: 100px;
	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.right,
	.left{
		display: flex;
		align-items: center;
		width: 370px;

		// @media (max-width: 1500px){
			
		// 	@media (min-width: $b2 + 1){
		// 		width: 144px;
		// 	}
		// }

		@media (max-width: $b2){
			width: auto;
			align-items: center;

			.btn-con{
				display: none;
			}
		}
	}

	.right{
		justify-content: flex-end;

		@media (max-width: 1500px){
			
			@media (min-width: $b2 + 1){
				
				.btn{
					font-size: 14px !important;
					font-size: 1.4rem !important;
					min-width: 144px;
					padding: 5px 5px;
				}
			}
		}

		.spacer{
			height: 48px;
			width: 1px;
			background-color: $white;
			margin-left: 32px;
			opacity: .6;

			@media (max-width: $b2){
				margin-left: 19px;
				height: 34px;
			}

			.dark body:not(.scroll-up):not(.scroll-down) &{
				background-color: #848b91;
			}
		}

		a{
			color: white;
			font-weight: 500;
			@include font-size(16);
			letter-spacing: .08em;
			display: flex;
			align-items: center;

			.dark body:not(.scroll-up):not(.scroll-down) &{
				color: $blue;

				svg{

					*{
						fill: $blue;
					}
				}

				&:hover,
				&:focus-visible{
					color: $brass;

					*{
						fill: $brass;
					}
				}
			}

			svg{

				*{
					fill: white;
					transition: $time;
				}
			}

			&:hover,
			&:focus-visible{
				color: $brass;

				*{
					fill: $brass;
				}
			}

			+ a{
				margin-left: 19px;
			}

			span{
				margin-left: 11px;
				display: block;

				@media (max-width: $b2){
					display: none;
				}
			}
		}
	}

	.left{

		@media (max-width: $b2){
			display: none;
		}

		.btn.small{
			min-width: 283px;

			.dark body:not(.scroll-up):not(.scroll-down) &{
				color: $blue!important;

				svg{

					*{
						fill: $blue!important;
					}
				}
			}
		}
	}
}

.menu-bottom-outer{
	position: absolute;
	top: 0%;
	right: 0;
	width: 551.5px;
	width: calc(551.5px + ((100vw - #{$base-width})/2)) ;
	background-color: white;
	height: 100vh;
	z-index: 2;
	padding: 188px 7.5px 51px 0;
	padding: 98px 7.5px 51px 0;
	transform: translateX(100%);
	transition: $time;
	background-image: url(../images/arrow-down.svg);
	background-repeat: no-repeat;
	background-position: bottom 18px left 525px;

	@media (min-width: 1358px + 1){
		padding-right: calc((100vw - #{$base-width})/2);
	}

	@media (max-width: 1358px){
		width: 551.5px;
		max-width: 100%;
		background-position: bottom 18px right 16px;
	}

	@media (max-width: $b2){
		padding: 100px 7.5px 51px 0;
		padding: 78px 7.5px 51px 0;
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		right: 100%;
		bottom: 0;
		width: 100vw;
		max-width: 100vw;
		background-color: rgba(13,33,43,0);
		pointer-events: none;
		transition: $time;

		.menu-open &{
			background-color: rgba(13,33,43,.85);
			pointer-events: auto;
		}
	}

	.menu-open &{
		transform: translateX(0%);
	}
	
	.col-12{
		max-height: calc(100vh - 188px - 51px);
		max-height: calc(100vh - 98px - 51px);
		overflow: auto;
		padding: 0;

		@media (max-width: $b2){
			max-height: calc(100vh - 78px - 51px);
		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;

	.hide-menu &{
		display: none;
	}
}

@media (min-width: $b2 + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $grey;
		}
	}
}

ul.small-menu.small-menu,
ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	text-align: right;

	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		flex-direction: column;
		text-align: right;

		@media (min-width: $b2 + 1){

			&:hover{

				&.menu-item-has-children{
					
					&:after,
					&:before{
						background-color: $brass;
					}
				}

				> a{
					color: $brass;
				}
			}
		}

		&.menu-item-has-children{
			padding-left: 50px;
			background-repeat: no-repeat;
			background-position: right 19px top 16px;
			position: relative;
			flex-wrap: wrap;

			&:after,
			&:before{
				content: '';
				position: absolute;
				top: 27.5px;
				left: 0px;
				transform: translateY(-50%) rotate(0deg);
				width: 18px;
				height: 2px;
				background-color: $blue;
				transition: $time;
				display: block;
				border-radius: 0px;
				border: none;

				@media (max-width: $b2){
					top: 15.5px;
				}
			}

			&:before{
				transform: translateY(-50%) rotate(-90deg);
			}

			&.open{

				&:before{
					transform: translateY(-50%) rotate(0deg);
				}

				.sub-menu{
					pointer-events: auto!important;
				}

				> .sub-menu{
					max-height: 1000px;
					position: relative;
					pointer-events: auto;

					&:after{
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						max-width: 100%;
						opacity: .4;
						visibility: visible;
					}
				}
			}
			
			// @media (min-width: $b2 + 1){

			// 	&:hover{

			// 		> a{
			// 			color: $brass;
			// 		}

			// 		> ul{
			// 			opacity: 1;
			// 			visibility: visible;
			// 			transition: $time;
			// 			pointer-events: auto;
			// 			z-index: 1;
			// 		}
			// 	}

			// 	&:hover{

			// 		> ul{
			// 			z-index: 2;
			// 		}
			// 	}
			// }

			.sub-menu{				
				position: static;
				opacity: 1;
				visibility: visible;
				background-color: transparent;
				transform: none;
				width: calc(100% + 50px);
				max-width: calc(100% + 50px);
				max-height: 0;
				margin-left: -50px;
				overflow: hidden;
				margin-bottom: 0;

				li{

					&:last-of-type{
						border-bottom: none;
					}
				}

				a{
					@include font-size(20);
					padding-right: 25px;

					@media (max-width: $b2){
						@include font-size(15);
						padding-right: 10px;
					}
				}
			}
		}

		> a{
			@include font-size(29);
			width: auto;
			color: $colour;
			line-height: 1.5em;
			text-decoration: none!important;
			font-weight: 400;
			position: relative;
			font-family: $alt-font;
			position: relative;
			padding: 5.5px 0;
			overflow: hidden;
			z-index: 2;
			letter-spacing: -.01em;

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus-visible{
					color: $brass;
				}
			}

			@media (max-width: $b2){
				@include font-size(19.6667);
				padding: 1.28px 0;
			}
		}
	}
}

ul.main-menu.main-menu{
	margin-bottom: 46px;
	margin-bottom: 23px;
}

ul.small-menu.small-menu{

	li{

		&.menu-item-has-children{

			&:after,
			&:before{
				top: 16.5px;
				width: 18px;
				height: 2px;

				@media (max-width: $b2){
					top: 12px;
				}
			}

			.sub-menu a{
				@include font-size(14);

				@media (max-width: $b2){
					@include font-size(12);
				}
			}
		}

		@media (min-width: $b2 + 1){

			&:hover{

				> a{
					color: $blue;
				}
			}
		}

		> a{
			@include font-size(18);
			font-family: $main-font;
			text-transform: uppercase;
			color: #b6a899;
			font-weight: 600;
			padding: 3.3px 0;
			letter-spacing: .075em;

			@media (max-width: $b2){
				@include font-size(14.3333);
				padding: .9px 0;
			}
		}
	}

}
#site-logo {
	transition: $time;
	svg {
		transition: $time;
		margin-left: auto;
		margin-right: auto;
	}
}
.scroll-down {
	header[role=banner]{
		transition: $time;
		transform: translateY(-100%);
	}
}
.scroll-up {
	header[role=banner]{
		transition: $time;
		position: fixed;
		transform: translateY(0%);
		background-color: $blue;
		#site-logo {
			@media (min-width: 767px){
				margin: 2rem auto;
				svg {
					max-width: 50%;
					margin: 0 auto;
				}
			}
		}
	}
}
