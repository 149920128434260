.propertyhive {

    .propertyhive-pagination {
        text-emphasis: center;
        padding-bottom: 90px;
        ul.page-numbers {
            text-align: center;
            li {
                list-style: none;
                padding-left: 0;
                margin: 4px;
                &:before {
                    display: none;
                }
                .page-numbers {
                    text-decoration: none;
                    display: block;
                    padding-bottom: 3px;

                    &.current {
                        border-bottom: 1px solid #b6a899;
                    }
                }
            }
        }
    }

    .toggle-group {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        @media (max-width: 992px) {
            display: none;
        }
       .icon-toggle {
           display: flex;
           align-items: center;
           text-transform: uppercase;
           font-size: 14px;
           font-family: $font-inter;
           font-weight: 600;
           color: #323E48;
           transition: ease-in-out all 0.4s;
           opacity: 0.5;
           letter-spacing: 0.08em;
           rect {
               transition: ease-in-out all 0.4s;
           }
           svg {
               rect {
                   fill:#323E48;
               }
           }
           &.active-toggle {
                opacity: 1;
           }
           svg {
               margin-right: 15px;
           }
       }
        .toggle-switcher {
            width: 80px;
            border: solid 2px $blue;
            border-radius: 30px;
            height: 37px;
            position: relative;
            margin:0 20px;
            cursor: pointer;
            .toggler {
                transition: ease-in-out all 0.4s;
                position: absolute;
                border-radius: 100%;
                background: $blue;
                top:5px;
                left:0;
                right: 0;
                margin: 0 auto;

                //top:0;
                //transform: translate(0%, -50%);
                height: 23px;
                width: 23px;
                &.left {
                    transform: translateX(-100%);
                }
                &.right {
                    transform: translateX(100%);
                }
            }
        }
    }

    .actions-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        @media (max-width: 992px) {
            justify-content: space-between;
        }
        @media (max-width: 600px) {
            flex-direction: column;
        }
        .propertyhive-result-count, select {
            margin-bottom: 0;
        }
        form {
            margin: 0;
        }

        select {
            background-color: transparent;
            background-image: url('../images/chevron-down.svg');
            border: none;
            text-transform: uppercase;
            font-size: 14px;
            font-family: $main-font;
            letter-spacing: 0.120em;
            font-weight: 500;
            color: $blue;
            option::nth-word(1){
                display: none;
            }
        }
    }


    .availability-badge {
        position: absolute;
        background: white;
        padding: 5px 24px;
        z-index: 9;
        left: 30px;
        top: 30px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $main-font;
        letter-spacing: 0.120em;
        font-weight: 500;
    }

    .properties {
        list-style: none!important;
        margin:0!important;
        // display: grid;
        // grid-template-columns: repeat(1, 1fr);
        // gap: 34px;
        padding-bottom: 90px!important;
        display: flex;
        flex-wrap: wrap;
        gap: 0;

        li.property {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            float: none !important;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 34px !important;

            .thumb-slider {
                display: block;
                width: 100%;
                height: 100%;
                .slick-list {
                    height: 100%;
                }
                .slick-track
                {
                    display: flex !important;
                    height: 100%;
                }
    
                .slick-slide
                {
                    height: inherit !important;
                }

                .slick-arrow {
                    position: absolute;
                    z-index: 100;
                    opacity: 1;
                    top: unset;
                    bottom: 30px;
                    width: 37px;
                    height: 37px;
                    background-color: $white;
                    color: $white;
                    background-position: center center;
                    background-repeat: no-repeat;
                    text-indent: -99999999px;
                    font-size:0;
                    line-height: 0;
                    border: none;
                    &:before {
                        content:"";
                        color: $blue;
                        font-size: 22px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left:0;
                        right:0;
                        margin: 0 auto;
                        text-align: center;
                    }

                    &.slick-prev {
                        right:62px;
                        left: unset;
                        background-image: url('../images/chevron-left.svg');
                    }
                    &.slick-next {
                        right:20px;
                        background-image: url('../images/chevron-right.svg');
                    }
                }
            }
            .thumb-slide {
                a {
                    display: block;
                    width: 100%;
                }
                img {
                    object-fit: cover;
                }
            }
        }

        li {
            padding-top:0!important;
            // width: 100%;
        }

        img {
            width: 100%;
            height: 100%!important;
            object-fit: cover;
        }

        .thumbnail {
            width: 30% !important;
            float: none !important;
        }

        .details {
            width: 70%!important;
            float: none !important;
            //.price {
            //    margin-top:20px;
            //}
            //.summary {
            //    margin-top: 20px;
            //}
            .summary {
                margin-top: 25px;
                margin-bottom: 25px;
            }
            .residential-details {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                padding:0;
                margin: 0;
                margin-bottom: 0px;
                li {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    padding: 0;
                    font-weight: 500;
                    color: #8E959A;
                    font-size: 14px;
                    letter-spacing: 0.120em;
                    &:not(:first-of-type){
                        margin-left: 25px;
                    }
                    svg {
                        margin-right: 15px;
                    }
                    &:before {
                        content:unset!important;
                    }
                }
            }
        }

        li {
            border-bottom:0!important;
            padding-bottom:0!important;
            padding:0;
            &:before, &:after {
                content: unset!important;
            }
        }

        .actions {
            display: none;
        }

        .price {
            font-size: clamp(18px, 1.5vw, 23px);
            color: #B6A899;
            font-family: $alt-font;
        }

        @media (max-width: 992px) {

        grid-template-columns: repeat(1, 1fr);

        .thumbnail, .details {
            float: none!important;
            width: 100% !important;
            img {
                width: 100%;
                height: 260px!important;
                object-fit: cover;
            }
        }
        li {
            padding:0;
            &:before, &:after {
                content: unset!important;
            }
        }
        .details {
            padding: 20px;
            padding: clamp(20px, 2.5vw, 30px);

            .residential-details {
                display: none;
            }

            h3 {
                max-width: 26ch;
            }

        }
        .summary {
            display: none;
        }
            li {
                display: flex;
                height: auto!important;
                flex-direction: column;
                .details {
                    padding: 30px;
                    padding:clamp(20px, 2.5vw, 50px);

                }
            }
        }


    }

    .details {
        background: white;
        text-transform: capitalize;

        h3 {
            a{
                text-decoration: none;
            }
        }
    }

    .thumbnail {
        position: relative;
    }

    .properties-grid {
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // gap: 34px;
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        margin-left: -17px !important;
        margin-right: -17px !important;
        // @media (max-width: 767px){
        //     grid-template-columns: repeat(2, 1fr);
        // }
        // @media (max-width: 562px){
        //     grid-template-columns: repeat(1, 1fr);
        // }
        .thumbnail, .details {
            float: none!important;
            width: 100% !important;
            img {
                width: 100%;
                height: 260px!important;
                object-fit: cover;
            }
            a {
                display: block;
                width: 100%;
            }
        }
        .thumb-slider {
            display: block;
            width: 100%;
            height: 260px !important;
            .slick-list {
                height: 100%;
            }
            .slick-track
            {
                display: flex !important;
                height: 100%;
            }

            .slick-slide
            {
                height: inherit !important;
            }
        }
        .thumb-slide {
            a {
                display: block;
                width: 100%;
            }
            img {
                object-fit: cover;
            }
        }
        li.property {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            padding: 0 17px;
            height: auto !important;
            margin-bottom: 34px !important;
            @media (max-width: 767px){
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (max-width: 562px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        li {
            padding:0;
            &:before, &:after {
                content: unset!important;
            }
        }
        .details {
            padding: 20px;
            padding: clamp(20px, 2.5vw, 30px);

            .residential-details {
                display: none;
            }

            h3 {
                max-width: 26ch;
            }

        }
        .summary {
            display: none;
        }
    }

    .properties-list {
        li {
            display: flex;
            height: auto!important;
           
            .details {
                padding: 30px;
                padding:clamp(20px, 2.5vw, 50px);

            }
        }
    }

    .details-beds {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        color: #8E959A;
        letter-spacing: 0.120em;
        display: block;
        margin-bottom: 15px;
    }


}

.ph-infinite-scroll-button {
    text-align: center;
    padding-bottom: 80px;
   a {
       cursor: pointer;
       font-size: 15px;
       text-transform: uppercase;
       font-weight: 600;
       padding-bottom:10px;
       border-bottom: solid 2px $brass;
   }
}

.properties-grid {
    .properties li{
        display: flex;
        flex-direction: column;
    }
    .properties li .details{
        height: 100%;
    }
}

.autocomplete-results ul li:before {
    content: unset;
}

.grecaptcha-badge {
    display: none!important;
}