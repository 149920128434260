.propertyhive {
    .property-top{
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        padding: 1px;
        .desc {
            padding-left: 30px;

            h1 {
                font-size: 45px;
                font-size:4.5rem;
                font-size:clamp(30px, 4vw, 4.5rem);
                line-height: 1.5;
            }

            .single-price {
                font-size: 45px;
                font-size:4.5rem;
                font-family: $alt-font;
                color:#B6A899;
                font-size:clamp(30px, 4vw, 4.5rem);
                line-height: 1.5;
            }
        }
        .imageslider {
            img {
                width: 100%;
                max-height: 500px;
                object-fit: cover;
            }
        }

    }
    .container-inner {
        margin: 0 auto;
        margin-top: 28rem;
        width: 100%;
        background: white;
        margin-bottom: 45px;
    }

    .flexslider {
        border-radius: 0;
    }


    .property-tabs {
        .property-tabs__headings .tab-heading div {
            display: flex;
            align-items: center;
        }

        .property-tabs__headings {
            //.container {
            //    display: flex;
            //    align-items: center;
            //    flex-wrap:  wrap;
            //}
            color: white;
            .tab-heading {
                padding: 26px 60px;
                @media (max-width: 1500px){
                    padding: 30px;
                }
                @media (max-width: 1056px){
                    padding: 20px;
                }
                @media (max-width: 992px){
                    padding: 15px;
                }
                @media (max-width: 767px){
                    max-width: 50%;
                    flex:100%;
                    div {
                        justify-content: center;
                        flex-direction: column;
                        span {
                            display: none;
                        }
                        svg {
                            margin:0;
                            margin-bottom: 5px;
                        }
                    }
                }
                text-align: center;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 0.120em;
                font-size: 14px;
                cursor: pointer;
                &.active-tab {
                    background: white;
                    color: $blue;
                    svg * {
                        fill: $blue;
                    }
                    border-radius: 7px 7px 0 0;

                }
            }

            svg {
                margin-right: 15px;
            }
        }
        .property-tabs__content{
            background: white;
            .tab-content:not(.active-tab) {
                display: none;
            }
        }
    }


}

.single-property {
    .type-property {
        .top-wrapper {
            margin-top: 285px;
            display: grid;
            align-items: center;
            grid-template-columns: 50% 50%;
            background:white;
            @media (max-width: 992px){
                margin-top: 150px;
                grid-template-columns: 1fr;
                display: block;
            }

            .details-beds {
                margin-bottom: 35px;
                display: block;
            }

        }

        .images {
            order: 2;
            position: relative;
            #slider {
                margin-bottom:0;
                .flex-prev {
                    right:62px;
                    left: unset;
                    background-image: url('../images/chevron-left.svg');
                }
                .flex-next {
                    right:20px;
                    background-image: url('../images/chevron-right.svg');
                }
                ul.flex-direction-nav a {
                    opacity: 1;
                    top: unset;
                    bottom: 30px;
                    width: 37px;
                    height: 37px;
                    background-color: $white;
                    color: $white;
                    background-position: center center;
                    background-repeat: no-repeat;
                    text-indent: -99999999px;
                    font-size:0;
                    line-height: 0;
                    &:before {
                        content:"";
                        color: $blue;
                        font-size: 22px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left:0;
                        right:0;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
                .slides {
                    &:after {
                        content: unset!important;
                    }
                    li {
                        &:before {
                            content: unset!important;
                        }
                        img {
                            height: 500px!important;
                            object-fit: cover;
                            @media (max-width:767px) {
                                height: auto!important;
                                object-fit: unset;
                            }
                        }
                    }
                }
            }
            #carousel {
                margin-bottom:0;
                border-top-width: 1px;
                .flex-direction-nav {
                    display: none;
                }
            }
            #carousel .slides > li {
                width: 223px!important;

                @media (max-width: 767px) {
                    width: 150px!important;
                }

                &:before, &:after {
                    content: unset!important;
                }

                img {
                    width: 100%;
                    height: 170px!important;
                    object-fit: cover;
                    @media (max-width: 767px) {
                        height: 120px!important;
                    }
                }
            }
            .flex-direction-nav {
                li {
                    &:before, &:after {
                        content: unset!important;
                    }

                }
            }
        }
    }
    .entry-summary {
        padding-top: 60px;
        padding-top: clamp(30px, 2.5vw, 60px);
        padding-left: 30px;
        padding-left: clamp(30px, 2.5vw, 64px);
        padding-right: 30px;
        padding-right: clamp(30px, 2.5vw, 45px);
        padding-bottom: 60px;
        padding-bottom: clamp(30px, 2.5vw, 60px);
    }

    h1 {
        font-size: 45px;
        font-size:4.5rem;
        margin-bottom: 30px;
        max-width: 25ch;
        font-size:clamp(30px, 4vw, 4.5rem);

        @media (max-width: 992px){
            max-width: 100%;
        }
    }

    .single-price {
        font-size: 45px;
        font-size:4.5rem;
        font-family: $alt-font;
        color:#B6A899;
        margin-bottom: 30px;
        display: block;
        font-size:clamp(30px, 4vw, 4.5rem);

    }
    .residential-details {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding:0;
        margin: 0;
        margin-bottom: 40px;
        li {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            padding: 0;
            font-weight: 500;
            color: #8E959A;
            font-size: 14px;
            letter-spacing: 0.120em;
            &:not(:first-of-type){
                margin-left: 25px;
            }
            svg {
                margin-right: 15px;
            }
            &:before {
                content:unset!important;
            }
        }
    }
    .description {
        border-top: solid 1px rgba(#727271, 0.2);
        padding-top: 40px;
        max-width: 47ch;
        @media (max-width: 992px){
            max-width: 100%;
        }
        p {
            margin-bottom: 50px;
        }
    }

    .booking-actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .btn {
            margin-right: 40px;
            @media (max-width: 494px){
                margin-right: 0;
                width: 100%;
                margin-bottom: 15px;
            }
        }
        a.tel {
            display: flex;
            align-items: center;
            svg {
                margin-right: 16px;
            }
            span {
                color: $blue;
                font-family: $alt-font;
                font-size:clamp(22px, 4.5vw, 45px);
            }
        }
    }
    .full-bleed {
        background: #ffffff;
        box-shadow: 0 0 0 100vmax #ffffff;
        clip-path: inset(0 -100vmax);
    }
    .property-tabs {
        margin-top: 47px;
        &__headings {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 992px){
                flex-wrap: wrap;
            }

            .share-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media(max-width: 992px){
                    display: none;
                }
                span {
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.120em;
                    text-transform: uppercase;
                }
                svg {
                    margin: 0;
                }
            }
        }
        &__content {
            padding-top: 30px;
            padding-top: clamp(30px, 5vw, 60px);
            padding-bottom: 30px;
            padding-bottom: clamp(30px, 5vw, 70px);

            .full-description {
                display: grid;
                grid-template-columns: 70% calc(30% - 88px);
                gap: 88px;

                @media (max-width: 1200px){
                    grid-template-columns: 70% calc(30% - 44px);
                    gap: 44px;
                }
            }
        }
    }
    .interested-cta {
        background: $brass;
        padding: 30px;
        padding: clamp(30px, 5vw, 50px);
        position: relative;

        svg {
            position: absolute;
            top:0;
            right: 40px;
            opacity: 0.5;
        }
        p {
            color: #FFFFFF;
        }
        a:first-of-type {
            margin-right: 25px;
        }
        @media (max-width: 767px){
            padding-top: 75px;
            a {
                width: 100%;
                &:first-of-type {
                    margin: 0;
                    margin-bottom: 15px;
                }
            }
        }
        h3 {
            color: white;
            font-size: 45px;
            font-size:4.5rem;
            font-size: clamp(30px, 3vw, 4.5rem);
            letter-spacing: -0.010em;
        }
    }
    .custom-list {

        li:before {
            width: 3px;
            height: 3px;
            border-radius: 24px;
            display: inline-block;
            background-color: $brass;
            left:0;
        }
        li:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .required-info {
        display: grid;
        grid-template-columns: 70% 30%;
        grid-template-columns: 70% calc(30% - 88px);
        gap: 88px;

        @media (max-width: 1200px){
            grid-template-columns: 70% calc(30% - 44px);
            gap: 44px;
        }
        .epcs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 60px;
        }
    }

    .map-view {
        display: grid;
        grid-template-columns: 25% 75%;
        gap: 88px;
        overflow: hidden;

        .office-telephone-number {
            margin-top: 36px;
        }
        .btn {
            margin-top: 40px;
        }
    }

    .floorplans {
        .full {
            max-width: 80%;
            margin: 0 auto;
            img {
                margin: 0 auto;
            }
        }
    }


}

.similar-properties {
    background-color: #F0F0F0;
    padding: 70px 0 50px;
    .thumb-slider {
        display: block;
        width: 100%;
        height: 100%;
        .slick-list {
            height: 100%;
        }
        .slick-track
        {
            display: flex !important;
            height: 100%;
        }

        .slick-slide
        {
            height: inherit !important;
        }

        .slick-arrow {
            position: absolute;
            z-index: 100;
            opacity: 1;
            top: unset;
            bottom: 30px;
            width: 37px;
            height: 37px;
            background-color: $white;
            color: $white;
            background-position: center center;
            background-repeat: no-repeat;
            text-indent: -99999999px;
            font-size:0;
            line-height: 0;
            border: none;
            display: block !important;
            &:before {
                content:"";
                color: $blue;
                font-size: 22px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left:0;
                right:0;
                margin: 0 auto;
                text-align: center;
            }

            &.slick-prev {
                right:62px;
                left: unset;
                background-image: url('../images/chevron-left.svg');
            }
            &.slick-next {
                right:20px;
                background-image: url('../images/chevron-right.svg');
            }
        }
    }
    .thumb-slide {
        a {
            display: block;
            width: 100%;
        }
        img {
            object-fit: cover;
        }
    }
}

@media(max-width: 767px){
    .property-tabs__content {
        .tab-content {
            gap: 40px!important;
            grid-template-columns: 1fr!important;
        }
    }
}

.property_actions {
    display: none;
}

.similar-slider {
    overflow: hidden;
    .thumbnail {
        img {
            width: 100%;
            height: 260px!important;
            object-fit: cover;
        }
    }
    .details {
        margin: 0;
        padding: 30px;
        h3 {
            max-width: 26ch;
        }

    }
    li {
        padding: 0;
    }
    li:before {
        content: unset;
    }
    .price {
        font-size: clamp(18px, 1.5vw, 23px);
        color: #B6A899;
        font-family: $alt-font;
    }

    .summary, .residential-details, .actions {
        display: none;
    }
    .details-beds {
        display: block;
        margin-bottom: 15px;
    }

}

.similar-properties {
    .view-all {
        cursor: pointer;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;
        padding-bottom:10px;
        border-bottom: solid 2px $brass;
        margin-right: 30px;
    }
    &__header {
        display: flex;
        flex-wrap:wrap;
        align-items: center;

        @media (max-width: 540px){
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        margin-bottom: 43px;
        h2 {
            margin-bottom:0;
        }

    }
    .misc-wrapper {
        margin-left: auto;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 540px){
            margin:0;
            margin-top: 2rem;
            justify-content: space-between;
            width: 100%;

        }
    }
    .button-wrapper {
        position: relative;
        display: flex;
        div {
            transform: none;
            position: relative;
            top:0;
            left:0;
            right:0;
            &:first-of-type {
                margin-right: 4px;
            }
        }
    }
}

.property-enquiry-form.property-enquiry-form.property-enquiry-form.property-enquiry-form{

    label{
        display: block;
        @include font-size(17);
        font-family: $main-font;
        margin-bottom: 16px;
        color: $grey;
    }
}