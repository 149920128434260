//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -5px;
	max-width: calc(100% + 5px + 5px);
	flex-wrap: wrap;

	.btn{
		margin: 0 5px 10px;

		&:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	padding: 10px 10px;
	font-size: 14px!important;
	font-size: 1.4rem!important;
	text-decoration: none!important;
	line-height: 1.5em;
	border: 2px solid $brass;
	color: $grey-lighter!important;
	font-weight: 500;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $main-font;
	position: relative;
	border-radius: 0;
	transition: $time;
	background-color: $brass;
	min-width: 199px;
	justify-content: center;
	text-transform: uppercase;
	letter-spacing: .12em;

	svg{

		*{
			fill: white!important;
		}

		+ span{
			margin-left: 10px;
		}
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $blue;
		border-color: $blue;
		color: $white!important;
	}

	@media (max-width: $b2){
		font-size: 16px!important;
		font-size: 1.6rem!important;
		min-width: 200px;
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{

	&.transparent{
		background-color: transparent;
		border-color: $brass;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: $brass;
			border-color: $brass;
			color: $white!important;
		}
	}

	&.blue-outline {
		border-color: $blue;
		background-color: transparent;
		color: $blue!important;
		&:hover,
		&:focus-visible{
			background-color: $blue-light;
			border-color: $blue-light;
			color: $white!important;
		}
	}

	&.blue{
		background-color: $blue;
		border-color: $blue;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: $blue-light;
			border-color: $blue-light;
			color: $white!important;
		}
	}

	&.big{
		padding: 19px 35px;
	}

	&.small{
		padding: 13px 10px 13px 5px;
		font-size: 13px!important;
		font-size: 1.3rem!important;
		letter-spacing: .08em;
	}
}