footer{
	position: relative;
	background-color: $blue;
	background-position: 50% 0;

	*{
		color: white;
	}

	.footer-top-outer{
		padding: 69px 0 0;
		border-top: 10px solid $brass;
		text-align: center;
		
		@media (max-width: $b2){
			padding: 50px 0 0;
		}

		p{
			text-transform: uppercase;
			@include font-size(14);
			font-weight: 500;
			letter-spacing: .13em;
			margin-bottom: 0.8em;

			@media (max-width: $b2){
				@include font-size(12);
			}
		}

		.links{
			@include font-size(14);
			letter-spacing: .13em;
			color: $grey-medium;
			margin-bottom: 0.65em;
			text-transform: uppercase;

			@media (max-width: $b2){
				@include font-size(12);
			}

			a{
				color: white;
				font-weight: 500;

				&:hover,
				&:focus-visible{
					color: $brass;
				}
			}
		}

		#footer-logo{
			display: block;
			height: auto;
			max-width: 100%;
			margin: -19px auto 19px;
			transition: $time;
			z-index: 1;
			width: 139.4686px;
			z-index: 50;
			transition: 0s;

			@media (max-width: $b2){
				margin: 19px auto 19px;
				width: 110px;
			}

			svg,
			img{
				display: block;
				width: 100%;
			}
		}

		.social-menu{
			padding-top: 1em;

			@media (max-width: $b3){
				padding-top: 0;
			}
		}

	}

	.footer-middle-outer{
		padding: 36px 0 26px;

		.footer-menu{
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			@include font-size(16);
			line-height: 1.125;
			justify-content: center;

			li{
				@include font-size(14);
				line-height: 1.25;
				padding: 0 14px;
				margin-bottom: 14px;
				
				+ li{
					border-left: 1px solid $grey-medium;
				}

				a{
					@include font-size(14);
					line-height: 1.25;
					font-weight: 500;
					text-decoration: none;
					color: $white;
					display: block;
					text-transform: uppercase;
					letter-spacing: .12em;

					@media (max-width: $b2){
						@include font-size(12);
					}

					&:hover,
					&:focus-visible{
						color: $brass!important;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		background-color: $blue-light;
		padding: 17px 0;
		text-align: center;

		.copy{
			color: $grey-medium;
			@include font-size(15);

			@media (max-width: $b2){
				@include font-size(12);
			}

			a{
				color: white;

				&:hover,
				&:focus-visible{
					color: $brass;
				}
			}
		}
	}
}