.propertyhive {
    #container {
        //max-width: 1375px;
        //width: calc(100% - 60px);
        @extend .container;
    }
    .page-wrapper {
        background: #F3F3F4;
    }
}
body.single-property {
    background: $blue;
    .page-wrapper {
        background: transparent;
    }
}
