@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Light.woff2') format('woff2'),
        url('../fonts/Inter-Light.woff') format('woff'),
        url('../fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter-Medium.woff') format('woff'),
        url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/Inter-SemiBold.woff') format('woff'),
        url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('../fonts/DMSerifDisplay-Regular.woff2') format('woff2'),
        url('../fonts/DMSerifDisplay-Regular.woff') format('woff'),
        url('../fonts/DMSerifDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

//BREAK POINTS
$b1:				1333px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red:				#c30007;

$green:				#1ea400;

$blue-light:		#47515a;
$blue:				#323e48;

$brass:				#b6a899;

$brass-form:		#a3978a;

$grey-lighter:		#ededed;
$grey-light:		#cdcfd1;
$grey-medium:		#8e959a;
$grey:				#464746;

$grey-form:			#f0f1f2;
$grey-form-text:	#b7bbbf;

$white:				#ffffff;
$black:				#000000;

$colour: 			$grey;
$main-bg-colour:	$white;

$font-inter:		'Inter', serif;
//light - 300
//regular - 400
//medium - 500
//semin - 600

$font-dm:			'DM Serif Display', serif;
//regular - 400

$main-font:			$font-inter;
$alt-font:			$font-dm;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	13px !default;

//!!PAGE WIDTH
$base-width:		1333px;

//!!SITE TRANSITIONS
$time:				.5s;