.propertyhive {
    .property-search-form {
        background: white;
        border-radius: 3px;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
        display: flex;
        //grid-template-columns: repeat(16, 1fr);
        margin-top: -35px;
        z-index: 9;
        position: relative;
        flex-wrap: wrap;
        padding:12px 8px 12px 8px;
        margin-bottom: 70px;
        //@media (max-width: 540px){
        //    display: none;
        //}

        select {
            margin-bottom: 0;
            background-color: transparent;
            border: none;
            text-align: left;
            height: 45px;
            line-height: 45px;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            color: #323E48;
            //min-width: 175px;
            font-weight: 900;
            text-align: center;
            padding: 0;
            padding-left: 10px;
            padding-right: 31px;
	        background-position: right -17px top 50%;

            @media (max-width: 1330px){
                font-size: 10px;
            }

            @media (max-width: 1200px){
                min-width: 180px;
            }

            @media (max-width: 767px){
                font-size: 14px;
            }
        }

        .control-address_keyword{
            position: relative;

            .current-location{
                right: 10px!important;
                width: 18px!important;
                top: 50%!important;
                bottom: auto!important;
                transform: translateY(-50%)!important;
            }
        }

        [name="address_keyword"]{
            height: 45px;
            line-height: 45px;
            padding: 0;
            padding-left: 10px;
            padding-right: 31px;
            border: none;
            background-color: transparent;
            margin-bottom: 0;

            @media (max-width: 1330px){
                font-size: 10px;
            }

            @media (max-width: 1200px){
                min-width: 180px;
            }
            
            @media (max-width: 767px){
                font-size: 14px;
            }
        }
        .control {
            border-right: solid 1px #D6D8DA;
            @media (max-width: 767px){
                border: none;
            }
        }

        .control-minimum_bedrooms,
        .control-maximum_bedrooms {
            @media (min-width: 768px){
                flex: 0 1 10% !important;
            }
        }

        input[type=submit]{
            margin:0;
            background-image: url('../images/search.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 28px;
            text-indent:-9999px;
            line-height: 0;
            font-size:0;
            display:block;
            background-color: transparent;
            min-width: unset!important;
            border: none;
            padding: 0;
            width: 65px;
            //flex: 0 1 7%;
            margin-left: auto;
            margin-right: 0;

            @media (max-width: 540px){
                flex-basis: 100%;
                height: 65px;
            }

        }
    }

    .control-department {
        display: flex!important;
        vertical-align: middle;
        padding: 0px;
        position: relative;
        align-items: center;
        padding-right: 20px!important;
        @media (max-width: 1200px){
            flex-basis: 100%;
            justify-content: center;
            margin-bottom: 15px;
        }
        @media (max-width: 480px){
            //flex-direction: column;
            align-items: flex-start;
        }
        @media (max-width: 380px){
            flex-direction: column;

        }
        label {
            position: relative;
            padding-left:30px;
            margin-right: 10px;
            &:last-of-type {
                margin-right:0;
            }
            &:before {
                content:"";
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                background: #D6D8DA;
                width: 20px;
                height: 20px;
                left: 0;
                border-radius: 100%;
            }
            &:has(input:checked){
                &:before {
                    border: solid 4px #D6D8DA;
                    background: $blue;
                }
            }
        }
    }

    label {
        margin-bottom: 0;
        color: #323E48;
        font-family: $alt-font;
        letter-spacing: -0.010em;
        font-size: clamp(16px, 20px, 23px);
        display: inline-block;
        width: max-content;
        input {
            width: 0%;
            margin:0;
            border: none;
            line-height: 0;
            font-size: 0;
        }
    }

    .control:not(.control-department) {
        //max-width: 160px;
        padding: 0;
        flex: 1;
        label {
            display: none;
        }
    }



}

.hero-search {
    background: $blue;
    .property-search-form {
        margin-bottom:-34px;
    }
}